import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout/Layout';
import Section from '../../components/Section/Section';
import ShapeTitle from '../../components/ShapeTitle/ShapeTitle';
import { Product } from '../../modules/product/product';
import Detail from '../../routes/product/Detail';

export const query = graphql`
  query ($productId: String!) {
    product: catalogue(id: { eq: $productId }) {
      ...CatalogueProductDetail
    }
  }
`;

type Props = {
  data: {
    product: Product;
  };
  pageContext: {
    variantId: string;
  };
};

const Luna: React.FC<Props> = ({ data, pageContext }) => {
  const variant = data.product.variants.find(
    (_) => _.id === pageContext.variantId
  );

  if (!variant) {
    throw new Error(`Variant ${pageContext.variantId} not found.`);
  }

  const name = variant.name
    ? `${data.product.name} - ${variant.name}`
    : data.product.name;

  return (
    <Layout
      title="La Poupée qui aime la planète !"
      crumbs={[
        { name: 'Accueil', path: '/' },
        { name: 'Shop', path: '/shop/' },
        { name: name, path: `/shop/${variant.canonical}/` },
      ]}
    >
      <SEO
        title={`${name} | Poupée Odette`}
        description={data.product.description}
      />

      <Detail product={data.product} variant={variant} />

      <Section>
        <div className="mrr-auto mrl-auto pdr-15x pdl-15x md:mw-650x lg:mw-full lg:flex lg:justify-center">
          <div className="mrb-40x lg:mrr-45x">
            <p className="fsz-md mrb-10x">Luna, la poupée…</p>
            <h2 className="ff-lora fsz-xxlg lh-xxlg fw-regular ws-nowrap">
              <span className="cherry">De l’intuition.</span>
              <br />
              <span className="pink">Du coeur.</span>
            </h2>
          </div>
          <div className="flex flex-1 flex-wrap justify-between items-center lg:mw-600x">
            <div>
              <div className="mrb-40x md:mw-270x">
                <ShapeTitle name="productAdele">« Luna » ?</ShapeTitle>
                <p className="fsz-md">
                  Un clin d’oeil pour tous les enfants rêveurs, qui suivent leur
                  instinct et leurs envies ! Que Luna leur porte chance 🍀
                </p>
              </div>
              <div className="mrb-40x md:mw-270x md:mrb-0">
                <ShapeTitle name="productExpression">Son expression</ShapeTitle>
                <p className="fsz-md mrb-20x">
                  Le regard de Luna est chargé de tendresse et de détermination,
                  sans artifices.
                </p>
                <p className="fsz-md mrb-20x">
                  Des yeux qui observent, qui parlent, des yeux actifs qui nous
                  embarquent.
                </p>
                <p className="fsz-md">
                  Une bouche qui est prête à prendre la parole !
                </p>
              </div>
            </div>
            <div className="md:mw-270x">
              <ShapeTitle name="productPower">Ses pouvoirs</ShapeTitle>
              <p className="fsz-md mrb-20x">
                La meilleure alliée pour prendre confiance et déployer ses
                ailes.
              </p>
              <p className="fsz-md mrb-20x">
                <strong>Son quartz rose cousu à l’intérieur</strong> appellent
                au <strong>calme</strong> et à la <strong>sérénité</strong>.
                Luna accompagne les émotions de votre enfant. Un petit câlin
                tout doux avec Luna et la magie opère !
              </p>
              <p className="fsz-md">
                Luna apporte aussi toute l’énergie et le courage pour dépasser
                ses peurs et devenir ce que votre boutchou a envie d’être chaque
                jour !
              </p>
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default Luna;
